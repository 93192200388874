import React from 'react';
import './index.css';

import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import App from "./app";
import Home from "./home";
import Calendar from "./calendar";
import Bio from "./bio";
import Recordings from "./recordings";
import RecordingsDefault from "./recordingsdefault";
import Composing from "./composing";
import Contact from "./contact";
import Donate from "./donate";
import FYC from "./fyc";
import GPJ from "./gpj";
import Maintenance from "./maintenance";
import Albums from "./albums";

import MusicAllianceProject from "./pages/musicallianceproject";
import Trio from "./pages/trio";
import Gapi from "./pages/gapi";

import store from './redux/store';
import { Provider } from 'react-redux';

import ScrollToTop from "./helper/scrollToTop";


const root = ReactDOM.createRoot(
  document.getElementById("root")
);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<App />}>    
          <Route index element={<Home />} />
          <Route path="Bio" element={<Bio />} />
          <Route path="Calendar" element={<Calendar />} />
          <Route path="Recordings" element={<Recordings />}>
            <Route index element={<RecordingsDefault />} />
            <Route path=":album" element={<Albums />} />
          </Route>
          <Route path="Composing" element={<Composing />} />
          <Route path="Contact" element={<Contact />} />
          <Route path="Donate" element={<Donate />} />
          <Route path="FYC" element={<FYC />} />
          <Route path="GPJ" element={<GPJ />} />

          <Route path="Trio" element={<Trio />} />
          <Route path="Gapi" element={<Gapi />} />
          <Route path="MusicAllianceProject" element={<MusicAllianceProject />} />

          <Route path="*" element={<Maintenance />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </Provider>
);

